<div class="container">
    <form [formGroup]="passengerForm" ngNativeValidate (ngSubmit)="onSubmit()" class="form-box">
        <a class="back-link" (click)="back()">&lt; Voltar</a>
        <h1>Dados do Passageiro</h1>
        <div class="form-row">
            <div class="label-div">
                <label for="nome">Primeiro Nome *</label>
                <input class="input-bar" type="text" formControlName="firstName" placeholder="Nome" required>
            </div>
            <div class="label-div">
                <label for="sobrenome">Sobrenome *</label>
                <input class="input-bar" type="text" formControlName="lastName" placeholder="Sobrenome" required>
            </div>
        </div>
        <div class="form-row-2 border">
            <div class="label-div">
                <label for="email">E-mail *</label>
                <input class="input-bar" type="email" formControlName="email" placeholder="exemplo@gmail.com" required>
            </div>
            <div class="label-div">
                <label for="telefone">Telefone *</label>
                <input class="input-bar" type="tel" formControlName="phone" placeholder="(47) 91234-5678"
                    pattern="\(\d{2}\)\s?\d{4,5}-?\d{4}" required>
            </div>
        </div>
        <div class="form-row">
            <p>* Informação Obrigatória</p> <button type="submit">Enviar</button>
        </div>
    </form>
</div>