<div class="container">
    <h2 class="deals-header">Promoções</h2>
    <p>Descubra nossas ofertas de vôo especiais:</p>
</div>

<div class="deals-list container">
    <a *ngFor="let deal of deals" class="deals-list-link" [routerLink]="['/',deal.id,'passenger']">
        <div class="city-container">
            <div class="city-box">
                <img class="deals-list-img" src="https://source.unsplash.com/random/?{{ deal.departureAirport.city }}"
                    alt="">
                <h4 class="deals-list-title"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                        viewBox="0 0 22 11" fit="" preserveAspectRatio="xMidYMid meet" focusable="false" class="">
                        <path fill-rule="evenodd"
                            d="M21.969 1.496c.234.677-.872 1.445-2.268 1.928-.696.242-3.198 1.108-5.68 1.966-.28.391-.773 1.076-.995 1.373-.22.296-.53.589-.89.713-.363.127-1.733.6-1.733.6l.719-1.682c-1.282.442-2.294.794-2.652.917-1.024.354-3.516.383-4.523.432-1.009.051-1.204-.22-1.534-.634C2.083 6.694 0 3.835 0 3.835l2.205-.357S5.027 4.81 5.537 5.002c.51.193.73.124 1.172-.05 1.58-.62 9.198-3.947 10.634-4.444 2.05-.71 4.39.311 4.626.988zm-6.411 8.223a.367.367 0 1 1 0 .734H8.394a.367.367 0 1 1 0-.734h7.164z"
                            class=""></path>
                    </svg> {{ deal.departureAirport.city }}</h4>
            </div>
        </div>
        <div class="city-container">
            <div class="city-box">
                <img class="deals-list-img" src="https://source.unsplash.com/random/?{{ deal.arrivalAirport.city }}"
                    alt="">
                <h4 class="deals-list-title"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
                        viewBox="0 0 21 10" fit="" preserveAspectRatio="xMidYMid meet" focusable="false" class="">
                        <path
                            d="M16.739 3.053c2.016 0 3.761 1.607 3.761 2.273 0 .666-1.203 1.005-2.575 1.005h-.993a.643.643 0 1 1-.909 0H12.34c-.365.258-1.006.71-1.292.905-.283.191-.644.355-.999.355H8.346l1.142-1.26H6.881c-1.007 0-3.204-.732-4.103-.995-.9-.262-.989-.559-1.154-1.023C1.46 3.85.5.705.5.705l2.046.357s2.071 2.026 2.462 2.351c.389.325.601.33 1.043.312 1.576-.064 9.277-.672 10.688-.672zm-2.913 6.058a.34.34 0 1 1 0 .683H7.17a.341.341 0 1 1 0-.683h6.656z"
                            class="st-current"></path>
                    </svg>{{ deal.arrivalAirport.city }}</h4>
            </div>
        </div>
        <div class="svg-box"><svg class="svg-clock" xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" viewBox="0 -256 1850 1850" id="svg3025"
                version="1.1" inkscape:version="0.48.3.1 r9886" width="100%" height="100%"
                sodipodi:docname="calendar_font_awesome.svg">
                <defs id="defs3033" />
                <g transform="matrix(1,0,0,-1,91.118644,1297.8644)" id="g3027">
                    <path
                        d="M 128,-128 H 416 V 160 H 128 v -288 z m 352,0 H 800 V 160 H 480 V -128 z M 128,224 H 416 V 544 H 128 V 224 z m 352,0 H 800 V 544 H 480 V 224 z M 128,608 H 416 V 896 H 128 V 608 z m 736,-736 h 320 V 160 H 864 V -128 z M 480,608 H 800 V 896 H 480 V 608 z m 768,-736 h 288 V 160 H 1248 V -128 z M 864,224 h 320 V 544 H 864 V 224 z m -352,864 v 288 q 0,13 -9.5,22.5 -9.5,9.5 -22.5,9.5 h -64 q -13,0 -22.5,-9.5 Q 384,1389 384,1376 v -288 q 0,-13 9.5,-22.5 9.5,-9.5 22.5,-9.5 h 64 q 13,0 22.5,9.5 9.5,9.5 9.5,22.5 z m 736,-864 h 288 V 544 H 1248 V 224 z M 864,608 h 320 V 896 H 864 V 608 z m 384,0 h 288 V 896 H 1248 V 608 z m 32,480 v 288 q 0,13 -9.5,22.5 -9.5,9.5 -22.5,9.5 h -64 q -13,0 -22.5,-9.5 -9.5,-9.5 -9.5,-22.5 v -288 q 0,-13 9.5,-22.5 9.5,-9.5 22.5,-9.5 h 64 q 13,0 22.5,9.5 9.5,9.5 9.5,22.5 z m 384,64 V -128 q 0,-52 -38,-90 -38,-38 -90,-38 H 128 q -52,0 -90,38 -38,38 -38,90 v 1280 q 0,52 38,90 38,38 90,38 h 128 v 96 q 0,66 47,113 47,47 113,47 h 64 q 66,0 113,-47 47,-47 47,-113 v -96 h 384 v 96 q 0,66 47,113 47,47 113,47 h 64 q 66,0 113,-47 47,-47 47,-113 v -96 h 128 q 52,0 90,-38 38,-38 38,-90 z"
                        id="path3029" inkscape:connector-curvature="0" style="fill:currentColor" />
                </g>
            </svg>
            <p> {{ deal.departureTime | datePipe }}</p>
        </div>
        <p class="deals-list-editora">A Partir de R$ {{ deal.basePrice }},00</p>
        <a [routerLink]="['/',deal.id,'passenger']" class="more-details-button">Reserve agora</a>
    </a>
    <a class="more-deals-button" href="">Explore todas as ofertas</a>
</div>