<div class="airplane-div">
    <div class="welcome-div">
        <h1>Sua viagem começa aqui.</h1>
        <p>Aproveite as ofertas da VoeFácil! Reserve agora e aproveite a experiência e comodidade de voar conosco.</p>
        <a href="#">Ver Promoções ></a>
    </div>
</div>

<div class="container">
    <div class="tab-container">
        <div class="tab"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 17" fit=""
                preserveAspectRatio="xMidYMid meet" focusable="false">
                <path
                    d="M18.214 7.505c-1.325-.09-3.24-.105-5.368-.07L8.955.994H7.092L8.543 7.56a201.37 201.37 0 0 0-5.354.282v-.026L2.005 5.888H.5V12.1h1.505l1.184-1.93v-.024c1.62.108 3.476.206 5.354.282l-1.451 6.566h1.863l3.891-6.441c2.128.035 4.043.02 5.368-.07 1.006-.07 2.242-.67 2.286-1.489-.044-.82-1.28-1.42-2.286-1.489">
                </path>
            </svg><span>Reservar um Voo</span></div>
    </div>

    <form class="search-box" ngNativeValidate (submit)="onSearch($event)">
        <svg class="svg-plane-1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 22 11"
            fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
            <path fill-rule="evenodd"
                d="M21.969 1.496c.234.677-.872 1.445-2.268 1.928-.696.242-3.198 1.108-5.68 1.966-.28.391-.773 1.076-.995 1.373-.22.296-.53.589-.89.713-.363.127-1.733.6-1.733.6l.719-1.682c-1.282.442-2.294.794-2.652.917-1.024.354-3.516.383-4.523.432-1.009.051-1.204-.22-1.534-.634C2.083 6.694 0 3.835 0 3.835l2.205-.357S5.027 4.81 5.537 5.002c.51.193.73.124 1.172-.05 1.58-.62 9.198-3.947 10.634-4.444 2.05-.71 4.39.311 4.626.988zm-6.411 8.223a.367.367 0 1 1 0 .734H8.394a.367.367 0 1 1 0-.734h7.164z">
            </path>
        </svg>
        <svg class="svg-plane-2" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 21 10"
            fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
            <path
                d="M16.739 3.053c2.016 0 3.761 1.607 3.761 2.273 0 .666-1.203 1.005-2.575 1.005h-.993a.643.643 0 1 1-.909 0H12.34c-.365.258-1.006.71-1.292.905-.283.191-.644.355-.999.355H8.346l1.142-1.26H6.881c-1.007 0-3.204-.732-4.103-.995-.9-.262-.989-.559-1.154-1.023C1.46 3.85.5.705.5.705l2.046.357s2.071 2.026 2.462 2.351c.389.325.601.33 1.043.312 1.576-.064 9.277-.672 10.688-.672zm-2.913 6.058a.34.34 0 1 1 0 .683H7.17a.341.341 0 1 1 0-.683h6.656z">
            </path>
        </svg>
        <div class="search-row">
            <input class="search-bar" type="text" name="partida" placeholder="Partida" required [(ngModel)]="origin">
            <input class="search-bar" type="text" name="destino" placeholder="Destino" required
                [(ngModel)]="destination">
        </div>
        <div class="search-row">
            <input class="date-bar" type="date" id="date" name="trip-start" [value]="currentDate | date:'yyyy-MM-dd'"
                min="{{ currentDate | date:'yyyy-MM-dd' }}" [(ngModel)]="date">
            <select name="tipo" class="dropdown" [(ngModel)]="seatType">
                <option [value]="'ECONOMY'">Economy</option>
                <option [value]="'FIRST_CLASS'">First Class</option>
            </select>
            <button class="buscar" type="submit">Buscar</button>
        </div>
    </form>
</div>