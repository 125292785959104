<div class="container">
    <div class="container-2">
        <div class="box">
            <div class="svg-box" [style.background-color]="planeColor">
                <svg class="svg-plane" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="white"
                    viewBox="0 0 22 11" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path fill-rule="evenodd"
                        d="M21.969 1.496c.234.677-.872 1.445-2.268 1.928-.696.242-3.198 1.108-5.68 1.966-.28.391-.773 1.076-.995 1.373-.22.296-.53.589-.89.713-.363.127-1.733.6-1.733.6l.719-1.682c-1.282.442-2.294.794-2.652.917-1.024.354-3.516.383-4.523.432-1.009.051-1.204-.22-1.534-.634C2.083 6.694 0 3.835 0 3.835l2.205-.357S5.027 4.81 5.537 5.002c.51.193.73.124 1.172-.05 1.58-.62 9.198-3.947 10.634-4.444 2.05-.71 4.39.311 4.626.988zm-6.411 8.223a.367.367 0 1 1 0 .734H8.394a.367.367 0 1 1 0-.734h7.164z">
                    </path>
                </svg>
            </div>
            <p>Lista de Voos</p>
            <img src="/assets/line2.png" alt="">
        </div>
        <div class="box">
            <div class="svg-box" [style.background-color]="passengerColor">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="white" viewBox="0 0 15 18"
                    fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path fill-rule="evenodd"
                        d="M3.296 3.269A1.633 1.633 0 1 1 3.3.002a1.633 1.633 0 0 1-.005 3.267zm7.688 0A1.633 1.633 0 1 1 10.99.002a1.633 1.633 0 0 1-.005 3.267zm.29 9.54h-.577v4.367a.828.828 0 0 1-.825.824h-.065a.828.828 0 0 1-.825-.824V12.81H7.86s.064-.971.155-2.209c-.423-.106-.673-.571-.557-1.033l1.03-4.088c.22-1.084.777-1.767 1.681-1.767h1.633c.905 0 1.46.683 1.68 1.767l1.033 4.088c.115.462-.135.927-.558 1.033.091 1.238.156 2.209.156 2.209h-1.124v4.366c0 .452-.37.824-.825.824H12.1a.827.827 0 0 1-.825-.824V12.81zM6.058 5.57c.02.072.036.417.036.417v3.955h-.002a.794.794 0 0 1-.79.775v6.459a.828.828 0 0 1-.825.824h-.066a.827.827 0 0 1-.824-.824v-5.098h-.004a.287.287 0 0 0-.285-.269.287.287 0 0 0-.286.27h-.005v5.097c0 .452-.37.824-.824.824h-.066a.827.827 0 0 1-.824-.824v-6.46a.795.795 0 0 1-.791-.774H.5V5.987s.015-.345.037-.417c.178-1.053 1.017-1.857 2.018-1.857H4.04c1.001 0 1.84.804 2.018 1.857z">
                    </path>
                </svg>
            </div>
            <p>Passageiros</p>
            <img src="/assets/line2.png" alt="">
        </div>
        <div class="box">
            <div class="svg-box" [style.background-color]="seatColor">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="white" viewBox="0 0 16 15"
                    fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path fill-rule="evenodd"
                        d="M5.963 1.72a.79.79 0 0 1 .79-.79h2.589a.79.79 0 0 1 .789.79v1.04H5.963V1.72zm5.071 1.041V1.72c0-.933-.759-1.693-1.692-1.693h-2.59A1.694 1.694 0 0 0 5.06 1.72v1.042H2.02A1.954 1.954 0 0 0 .093 4.71l-.052 8.343A1.954 1.954 0 0 0 1.99 15H14a1.953 1.953 0 0 0 1.948-1.948L16 4.709a1.954 1.954 0 0 0-1.925-1.948h-3.04z">
                    </path>
                </svg>
            </div>
            <p>Assentos</p>
            <img src="/assets/line2.png" alt="">
        </div>
        <div class="box">
            <div class="svg-box" [style.background-color]="ticketColor">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="white" viewBox="0 0 18 15"
                    fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path fill-rule="evenodd"
                        d="M16.5 9.188 4.82 13.922a.86.86 0 0 1-1.128-.479L2.116 9.56l13.288-5.385 1.574 3.885a.869.869 0 0 1-.477 1.129zM1.019 6.849a.864.864 0 0 1 .478-1.128L13.178.987a.864.864 0 0 1 1.128.478l.221.546L1.24 7.396l-.222-.547zm16.845.852a1.822 1.822 0 0 1-1.004 2.37L5.177 14.805a1.815 1.815 0 0 1-2.37-1.002L.135 7.208c-.183-.45-.18-.945.01-1.393.19-.447.543-.795.993-.977L12.818.103a1.83 1.83 0 0 1 .684-.133c.745 0 1.407.445 1.687 1.136l2.674 6.595zm-5.564.81a.473.473 0 0 0-.277.625c.104.255.397.353.653.25l2.422-.983a.472.472 0 0 0 .276-.625c-.103-.256-.397-.353-.653-.25L12.3 8.51z">
                    </path>
                </svg>
            </div>
            <p>Ticket</p>
        </div>

    </div>
</div>