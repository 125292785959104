<div class="container">
    <div class="header">
        <a class="back-link" (click)="back()">&lt; Voltar</a>
        <h1>Mapa de assentos</h1>
        <p>Por favor, selecione o assento que deseja reservar.</p>
    </div>

    <div class="container-2">
        <div class="plane">
            <h3>First Class</h3>
            <div class="seat-box-firstclass">
                <div class="seat" *ngFor="let seat of seats.slice(0, 20)" [disabled]="seat.seatAvailability == false"
                    [class.selected]="selectedSeat === seat" (click)="selectSeat(seat)">
                    <ng-container *ngIf="seat.seatAvailability; else notAvailable">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="white"
                            viewBox="0 0 14 17" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path fill-rule="evenodd"
                                d="M6.997 7.637C4.92 7.634 3.243 5.926 3.243 3.82 3.243 1.716 4.921.002 6.997 0c2.08 0 3.76 1.716 3.76 3.82 0 2.106-1.68 3.814-3.76 3.817zM0 17v-3.52c0-2.642 2.129-4.805 4.73-4.805h4.54c2.601 0 4.73 2.163 4.73 4.805V17H0z">
                            </path>
                        </svg>
                    </ng-container>
                    <ng-template #notAvailable>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="white"
                            height="100%" width="100%" version="1.1" id="Capa_1" viewBox="0 0 460.775 460.775"
                            xml:space="preserve">
                            <path
                                d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55  c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55  c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505  c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55  l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719  c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z" />
                        </svg>
                    </ng-template>
                </div>
            </div>

            <h3>Economy</h3>
            <div class="seat-box-economy">

                <div class="seat" *ngFor="let seat of seats.slice(20)" [class.selected]="selectedSeat === seat"
                    (click)="selectSeat(seat)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="white" viewBox="0 0 14 17"
                        fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <path fill-rule="evenodd"
                            d="M6.997 7.637C4.92 7.634 3.243 5.926 3.243 3.82 3.243 1.716 4.921.002 6.997 0c2.08 0 3.76 1.716 3.76 3.82 0 2.106-1.68 3.814-3.76 3.817zM0 17v-3.52c0-2.642 2.129-4.805 4.73-4.805h4.54c2.601 0 4.73 2.163 4.73 4.805V17H0z">
                        </path>
                    </svg>
                </div>
            </div>
        </div>
        <div class="info-box">
            <div>
                <div class="info-header">
                    <h4>VoeFacil
                        <ng-container *ngIf="selectedSeat?.seatClass == 'FIRST_CLASS'; else economy">
                            FirstClass
                        </ng-container>
                        <ng-template #economy>
                            <ng-container *ngIf="selectedSeat?.seatClass == 'ECONOMY'; else empty">
                                Economy
                            </ng-container>
                        </ng-template>
                        <ng-template #empty>
                             
                        </ng-template>
                    </h4>
                    <p>{{ airline }}</p>
                </div>
                <div class="info-seat">
                    <h5>Assento</h5>
                    <ng-container *ngIf="!selectedSeat; else seat">
                        <h5>Selecione um assento.</h5>
                    </ng-container>
                    <ng-template #seat>
                        <h2> {{ selectedSeat?.seatNumber }} </h2>
                    </ng-template>
                </div>
                <div class="info-pricing" *ngIf="selectedSeat">
                    <ng-container class="pricing-box" *ngIf="selectedSeat?.seatClass == 'FIRST_CLASS'">
                        <h5>Valor da Passagem</h5>
                        <h2 class="price">R$ {{ flightPrice * 1.3 }},00</h2>
                    </ng-container>
                    <ng-container class="pricing-box" *ngIf="selectedSeat?.seatClass == 'ECONOMY'">
                        <h5>Valor da Passagem</h5>
                        <h2 class="price">R$ {{ flightPrice }},00</h2>
                    </ng-container>
                </div>

            </div>
            <button [disabled]="!selectedSeat" (click)="confirmSelection()">
                Realizar Reserva
            </button>
        </div>
    </div>
</div>